<template>
  <div class="pb-12 mb-8">
    <!-- intro video (temporary hide) -->
    <!-- <div class="my-8" v-if="displayInformations.intro_video">
			<div class="pink2--text mb-2">Intro Video</div>
			<div v-if="me.profile.intro_video_file">
				<video width="300" controls>
					<source :src="me.profile.intro_video_file.media_path" :type="me.profile.intro_video_file.mime_path">
					Your browser does not support the video tag.
				</video>
			</div>
			<div v-else>
				No intro video was attached.
			</div>
			<hr class="divider my-10">
		</div> -->
    <!-- intro summary -->
    <div class="my-8" v-if="displayInformations.intro_summary">
      <div class="pink2--text mb-2">Intro Summary</div>
      <div>
        {{ me.profile.intro_summary || '-' }}
      </div>
      <hr class="divider my-10" />
    </div>

    <!-- bio -->
    <div class="my-8" v-if="displayInformations.bio">
      <div class="pink2--text mb-2">Bio</div>
      <div>
        {{ me.profile.bio || '-' }}
      </div>
      <hr class="divider my-10" />
    </div>

    <!-- profile -->
    <div class="my-8">
      <div class="pink2--text mb-2">Profile</div>
      <v-row no-gutters class="px-3">
        <v-col cols="12" md="12" class="d-flex mb-5" 
          v-if="
              displayInformations.description && 
              displayInformations.designation && 
              me.description && me.designation
            "
        >
          <div class="mr-2" v-if="displayInformations.description">
            {{ me.description }}
          </div>
          <span 
            class="mx-2"
            v-if="
              displayInformations.description && 
              displayInformations.designation && 
              me.description && me.designation
            "
          >|</span>
          <div v-if="displayInformations.designation">
            {{ me.designation }}
          </div>
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.dob">
          <span class="font-weight-bold mr-2"
            >{{ $t('label.dateOfBirth') }}: </span
          >{{ me.dob || '-' }}
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.race">
          <span class="font-weight-bold mr-2">{{ $t('label.race') }}: </span
          >{{ getConstantValue('race') || '-' }}
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.gender">
          <span class="font-weight-bold mr-2">{{ $t('label.gender') }}: </span
          >{{ me.gender || '-' }}
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.pronouns">
          <span class="font-weight-bold mr-2">{{ $t('label.pronouns') }}: </span
          >{{ pronouns || '-' }}
        </v-col>
        <v-col cols="12" md="12" v-if="displayInformations.company_write_up">
          {{ me.profile.company_write_up || '-' }}
        </v-col>
      </v-row>
      <hr class="divider my-10" />
    </div>

    <!-- characteristics -->
    <div class="my-8" v-if="displayInformations.characteristics || customCharacteristicsFields">
      <div class="pink2--text mb-2">Characteristics</div>
      <v-row no-gutters class="px-3">
        <v-col cols="12" md="6" v-if="displayInformations.age_to_play">
          <span class="font-weight-bold mr-2"
            >{{ $t('label.onScreenAge') }}: </span
          >{{ me.profile.age_to_play || '-' }}
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.skills">
          <span class="font-weight-bold mr-2">{{ $t('label.skills') }}: </span
          >{{ skillsStr }}
        </v-col>
        <v-col cols="12" md="6" v-if="displayInformations.languages_spoken">
          <span class="font-weight-bold mr-2"
            >{{ $t('label.languagesSpoken') }}: </span
          >{{ languagesSpoken || '-' }}
        </v-col>
        <template v-if="displayInformations.characteristics">
          <v-col cols="12" md="6" v-for="c in characteristics" :key="c.key">
            <span class="font-weight-bold mr-2"
              >{{ $t(`label.${c.label}`) }}:
            </span>
            {{
              (['ethnicity', 'height', 'weight'].includes(c.key)
                ? me.profile[c.key]
                : getConstantValue(c.key)) || '-'
            }}
          </v-col>
          <v-col cols="12" md="6" class="d-flex mt-1">
            <span class="font-weight-bold mr-2"
              >{{ $t('label.skinColor') }}:
            </span>
            {{ me.profile.skin_color || '-' }}
            <div
              v-if="skinColor"
              class="skinColor ml-2"
              :style="{
                'background-color': `#${skinColor}`
              }"
            ></div>
          </v-col>
        </template>
        
      </v-row>
      <hr class="divider my-10" />
    </div>

    <!-- bio links -->
    <div class="my-8" v-if="displayInformations.bio_links">
      <div class="pink2--text mb-2">Bio Links</div>
      <div v-for="link in me.profile.bio_links" :key="link">
        <a :href="link" target="_blank" style="color: white">{{ link }}</a>
      </div>
      <div v-if="!me.profile.bio_links">No bio link was added.</div>
      <hr class="divider my-10" />
    </div>

    <!-- cv file -->
    <div class="my-8" v-if="displayInformations.cv_file">
      <div class="pink2--text mb-2">CV</div>
      <div v-if="me.profile.cv_file">
        <a
          target="_blank"
          :href="profile.cv_file.media_path"
          style="color: white"
        >
          Click here to view
        </a>
      </div>
      <div v-else>No CV file was attached.</div>
      <hr class="divider my-10" />
    </div>

    <!-- rates -->
    <div class="my-8" v-if="displayInformations.rates">
      <div class="pink2--text mb-2">Rates</div>
      <div class="px-3">
        <div class="pink2--text mb-2">Rate Per Day</div>
        <v-row no-gutters class="mb-5">
          <v-col cols="12" md="6" v-for="rate in ratePerDay" :key="rate.key">
            <span class="font-weight-bold mr-2">{{ rate.label }}: </span
            >{{ rates[rate.key] }}
          </v-col>
        </v-row>
        <div class="pink2--text mb-2">Rate Per Hour</div>
        <v-row no-gutters>
          <v-col cols="12" md="6" v-for="rate in ratePerHour" :key="rate.key">
            <span class="font-weight-bold mr-2">{{ rate.label }}: </span
            >{{ rates[rate.key] }}
          </v-col>
        </v-row>
      </div>
      <hr class="divider my-10" />
    </div>

    <!-- follow me -->
    <div class="my-8">
      <div class="pink2--text mb-5">Follow me</div>
      <div class="d-flex flex-wrap" v-if="me.profile">
        <div v-for="(item, index) in socialMedia" :key="index">
          <a
            v-if="me.profile[item]"
            :href="checkFullUrl(me.profile[item])"
            target="_blank"
            :style="{
              cursor: (me.profile[item] ? 'pointer' : 'default') + ' !important'
            }"
          >
            <img
              v-if="displayInformations[item]"
              :src="`/assets/image/icons/social-media__${item}.${
                item == 'twitter' || item == 'twitch' ? 'svg' : 'png'
              }`"
              alt=""
              class="me-2"
              width="50"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import constantsKey from '@/filters/constants-key.filter';
  import {
    LOOKUP_GET_SKILLS,
    LOOKUP_INITIAL_GET_SKILLS_STATE
  } from '@/store/lookup.module';

  export default {
    name: 'ProfileSection',
    props: ['me', 'profile', 'displayInformations'],
    data() {
      return {
        characteristics: [
          // {
          // 	key: 'ethnicity',
          // 	label: 'ethnicity'
          // },

          {
            key: 'height',
            label: 'heightCm'
          },
          {
            key: 'weight',
            label: 'weightKg'
          },
          {
            key: 'eye_color',
            label: 'eyeColor'
          },
          {
            key: 'hair_color',
            label: 'hairColor'
          },
          {
            key: 'hair_length',
            label: 'hairLength'
          },
          {
            key: 'hair_type',
            label: 'hairType'
          }
        ],
        ratePerDay: [
          {
            key: 'tv_online_rate',
            label: 'TV Online Rate'
          },
          {
            key: 'feature_film_rate',
            label: 'Feature Film Rate'
          },
          {
            key: 'commercial_rate',
            label: 'Commercial Rate'
          },
          {
            key: 'student_project_rate',
            label: 'Student Project Rate'
          },
          {
            key: 'public_service_announcement_rate',
            label: 'Public Service Announcement Rate'
          },
          {
            key: 'variety_rate',
            label: 'Variety Rate'
          }
        ],
        ratePerHour: [
          {
            key: 'voiceover_rate',
            label: 'Voiceover Rate'
          }
        ],
        socialMedia: [
          'website_link',
          'facebook',
          'instagram',
          'twitter',
          'youtube',
          'linkedin',
          'tiktok',
          'twitch'
        ],
        skills: [],
        
      };
    },
    computed: {
      getSkillsComplete() {
        return this.$store.state.lookup.skills.complete;
      },
      pronouns() {
        let newArr = [];
        this.me.pronouns?.forEach((pronounId) => {
          const pronoun = constantsKey('pronouns', pronounId);
          newArr.push(pronoun.description);
        });
        return newArr.join(', ');
      },
      customCharacteristicsFields() {
        return this.displayInformations.age_to_play &&
          this.displayInformations.languages_spoken &&
          this.displayInformations.skills
      },
      languagesSpoken() {
        let newArr = [];
        this.me?.profile?.languages_spoken?.forEach((languageId) => {
          const language = constantsKey('language_spoken', languageId);
          newArr.push(language.description);
        });
        return newArr.join(', ');
      },
      skinColor() {
        if (!this.me.profile.skin_color) return null;
        const color = this.$store.getters['constants/skin_color'].find(
          (x) => x.value == this.me.profile.skin_color
        );
        return color.text;
      },
      rates() {
        let ratesObj = {};
        this.ratePerDay.forEach((type) => {
          const rate = constantsKey('rate', this.me?.profile[type.key]);
          ratesObj = {
            ...ratesObj,
            ...Object.fromEntries([[type.key, rate.description || '-']])
          };
        });
        ratesObj = {
          ...ratesObj,
          ...Object.fromEntries([
            [
              'voiceover_rate',
              constantsKey('rate', this.me?.profile?.voiceover_rate)
                .description || '-'
            ]
          ])
        };
        return ratesObj;
      },
      skillsStr() {
        let skillStr = [];
        if (this.me?.profile?.skill_ids) {
          this.me?.profile?.skill_ids.forEach((x) => {
            const skillObj = this.skills.find((s) => s.value == Number(x));
            skillStr.push(skillObj?.description || '');
          })
          return skillStr.join(', ');
        }
        return '-';
      }
    },
    watch: {
      getSkillsComplete() {
        let response = this.$store.state.lookup.skills;
        if (response.complete) {
          this.importSkills(response);
        }
      }
    },
    methods: {
      async getSkills() {
        await this.$store.dispatch(LOOKUP_GET_SKILLS);
      },
      importSkills(response) {
        if (response.code == 200) {
          this.skills = response.data;
        }
        this.$store.dispatch(LOOKUP_INITIAL_GET_SKILLS_STATE);
      },
      mapSkillString(skillsArr) {
        let skillStr = [];
        if (skillsArr) {
          skillsArr.forEach((x) => {
            const skillObj = this.skills.find((s) => s.value == Number(x));
            skillStr.push(skillObj.description);
          })
          return skillStr.join(', ');
        }
        return '-';
      },
      getConstantValue(key) {
        if (!this.me.profile[key]) return '';
        const constant = this.$store.getters[`constants/${key}`].find(
          (x) => x.value == this.me.profile[key]
        );
        return constant.description || constant.text;
      },
      checkFullUrl(url) {
        if (url && !/^https?:\/\//i.test(url)) {
          return 'https://' + url;
        }
        return url;
      }
    },
    async mounted() {
      await this.getSkills();
    }
  };
</script>

<style scoped lang="scss">
  .skinColor {
    padding: 2px 50px;
    border-radius: 5px;
  }
</style>
