<template>
  <div>
    <h1 class="text-uppercase mb-5">Verify Account</h1>
    <AppFormField
      v-if="isAgencyOrProductionHouse"
      label="Unique Company Code"
      outlined
      v-model="companyCode"
    />
    <div v-if="!isAgencyOrProductionHouse">
      Please upload: <br />
      {{ gesture.message }}
    </div>
    <div class="row mt-5">
      <div class="col-md-6" v-if="!isAgencyOrProductionHouse">
        <img :src="gesture.file_url" width="200px" crossorigin="anonymous"/>
      </div>
      <div class="col-md-6 align-self-center">
        <CustomInput
          v-model="verificationFile"
          name="docFile"
          :label="isAgencyOrProductionHouse ? 'Supporting Document (Pdf)' : ''"
          id="docFile"
          :config="{
            accept: isAgencyOrProductionHouse ? '' : 'image/*'
          }"
          type="file"
          dark
          outlined
        />
      </div>
    </div>
    <div class="text-center mt-10">
      <v-btn class="btn1 mt-5" @click="submit" :disabled="!valid">
        Submit
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { PROFILE } from '@/api';

  export default {
    name: 'VerifyAccountModalContent',
    props: ['isAgencyOrProductionHouse', 'randomNum', 'constantsData'],
    components: {
      CustomInput
    },
    data: () => ({
      companyCode: '',
      verificationFile: null
    }),
    computed: {
      valid() {
        return (
          this.verificationFile &&
          (this.isAgencyOrProductionHouse ? Boolean(this.companyCode) : true)
        );
      },
      gesture() {
        if (this.randomNum) {
          const getGesture = this.constantsData.gesture.find(
            (x) => x.id === this.randomNum
          );
          return getGesture;
        }
        return null;
      }
    },
    methods: {
      async submit() {
        try {
          await PROFILE.submitVerification({
            gesture_id: this.gesture?.id,
            company_code: this.companyCode,
            verification_file: this.verificationFile?.file
          });
          this.$closeModal();
          this.$emit('success');
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      }
    }
  };
</script>

<style></style>
