<template>
  <div class="container">
    <!-- <div class="mt-6 d-flex justify-end">
      <v-icon color="white" class="icon" @click.stop="isOpened = !isOpened">
        mdi-dots-horizontal
      </v-icon> 
      
    </div> -->
    <div>
      <div class="mt-10 w-100">
        <ShowreelSection
          v-if="me.profile"
          :displayInformations="displayInformations"
          :profile="me.profile"
        />
        <div
          class="w-100 justify-center d-flex"
          v-if="me.profile && me.profile.company_showreel_video_file"
        ></div>
        <div class="section-title d-flex my-10">
          <div class="profilePic-container">
            <v-avatar size="80">
              <img :src="profilePhoto" crossorigin="anonymous" style="object-fit: cover"/>
            </v-avatar>
            <!-- <v-avatar size="80" v-else>
              <v-img
               v-if="me.profile && me.profile.headshot_file"
                :src="getProfilePhoto('headshot_file')"
              ></v-img>
            </v-avatar> -->
            <div class="verificationStatus" v-if="me.profile">
              <v-icon
                color="#FF2FF2"
                class="icon"
                v-if="me.profile.verification_status == 'approved'"
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
          </div>
          <div class="ml-5 align-self-center">
            <div class="lg-font" v-if="me.profile">
              {{ me.display_name || me.profile.display_name }}
            </div>
            <div class="d-flex">
              <div class="d-flex" v-if="displayInformations.country">
                <img
                  class="me-2"
                  :src="getCountry(me.country_id[0]).image_url"
                  width="35px"
                  crossorigin="anonymous"
                />
                <span class="me-2">{{
                  getCountry(me.country_id[0]).name
                }}</span>
                <template
                  v-if="me.role == 'talent' && displayInformations.description"
                >
                  <span class="mx-2">|</span>
                  <span class="ml-2">{{ projectRolesString }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="ml-auto align-self-center" v-if="!$isMobile()">
            <v-btn
              id="verifyModal"
              class="btn1 fit outline"
              @click="openVerifyAccountModal"
              v-if="
                me.profile &&
                !['approved', 'submitted'].includes(
                  me.profile.verification_status
                ) &&
                authUser.id == me.id
              "
            >
              {{
                me.profile.verification_status == 'rejected'
                  ? 'Not Verified'
                  : 'Verify Account'
              }}
            </v-btn>
            <v-btn
              class="btn1 fit outline"
              disabled
              v-if="
                me.profile &&
                ['submitted'].includes(me.profile.verification_status) &&
                authUser.id == me.id
              "
            >
              Pending Approval
            </v-btn>
            <v-btn
              class="btn1 fit"
              :class="me.followed ? 'outline' : 'gradient'"
              @click="followUser"
              v-if="userId && authUser.id != me.id"
            >
              {{ me.followed ? 'Following' : 'Follow' }}
            </v-btn>
          </div>
        </div>
        <div v-if="$isMobile()">
          <v-btn
            class="btn1 fit outline w-100"
            @click="openVerifyAccountModal"
            v-if="
              me.profile &&
              !['approved', 'submitted'].includes(
                me.profile.verification_status
              ) &&
              authUser.id == me.id
            "
          >
            Verify Account
          </v-btn>
          <v-btn
            class="btn1 fit w-100"
            :class="me.followed ? 'outline' : 'gradient'"
            @click="followUser"
            v-if="userId && authUser.id != me.id"
          >
            {{ me.followed ? 'Following' : 'Follow' }}
          </v-btn>
        </div>
        <v-row no-gutters justify="center" align="center" class="grey-bg my-5">
          <v-col cols="12" md="3" class="text-center">
            <router-link
              class="text-decoration-none white--text"
              :to="{
                name: routeName.FOLLOWER_FOLLOWING,
                params: {
                  tab: 'followers'
                },
                query: {
                  id: authUser.id == me.id ? null : me.id
                }
              }"
            >
              <p class="mt-4">Followers</p>
              <p class="pink2--text">{{ me.follower }}</p>
            </router-link>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <router-link
              class="text-decoration-none white--text"
              :to="{
                name: routeName.FOLLOWER_FOLLOWING,
                params: {
                  tab: 'followings'
                },
                query: {
                  id: authUser.id == me.id ? null : me.id
                }
              }"
            >
              <p class="mt-4">Following</p>
              <p class="pink2--text">{{ me.following }}</p>
            </router-link>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="text-center"
            v-if="displayInformations.talents_no"
          >
            <router-link
              class="text-decoration-none white--text"
              :to="{
                name: routeName.FOLLOWER_FOLLOWING,
                params: {
                  tab: 'talents'
                },
                query: {
                  id: authUser.id == me.id ? null : me.id
                }
              }"
            >
              <p class="mt-4">Talents</p>
              <p class="pink2--text">{{ me.talents_no }}</p>
            </router-link>
          </v-col>
        </v-row>
        <RatingAndReviews 
          class="my-12" 
          :userRatings="userRatings" 
          :userId="userId"
          v-if="userRatings.totalRatingCount"
        />
        <ProfileSection
          :me="me"
          :profile="me.profile"
          :displayInformations="displayInformations"
        />
        <!-- <div class="mb-12">
          <div class="pink2--text">Profile</div>
          <div v-if="me.profile">
            {{ me.profile.company_write_up }}
          </div>
          <hr class="divider mt-10">
        </div>
        <div class="mb-12">
          <div class="pink2--text">Project</div>
          <div v-if="me.profile">
            {{ me.profile.list_projects }}
          </div>
          <hr class="divider mt-10">
        </div>
        <div class="mb-12">
          <div class="pink2--text mb-5">Follow us</div>
          <div class="d-flex flex-wrap" v-if="me.profile">
            <a 
              v-for="(item, index) in socialMedia"
              :key="index"
              :href="me.profile[item]"
              target="_blank"
            >
              <img 
                :src="`/assets/image/icons/social-media__${item === 'whatsapp' ? 'whatsApp' : item}.png`" 
                alt=""
                class="me-2"
                width="50"
              >
            </a>
          </div>
        </div> -->
      </div>
    </div>
    <ModalBase id="verifyAccount">
      <VerifyAccount
        :isAgencyOrProductionHouse="isAgencyOrProductionHouse"
        :randomNum="randomNum"
        :constantsData="constantsData"
        @success="verificationSubmit"
      />
    </ModalBase>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { bus } from '@/main.js';
  import ShowreelSection from '@/components/profile/ShowreelSection.vue';
  import RatingAndReviews from '@/components/profile/RatingAndReviews.vue';
  import ProfileSection from '@/components/profile/ProfileSection.vue';
  import VerifyAccount from '@/components/profile/VerifyAccountModalContent.vue';
  import { ROUTE_NAME, LOCAL } from '@/constants';
  import ModalBase from '@/components/base/ModalBase';
  import { uiHelper } from '@/utils';
  import { PROFILE, AUTH } from '@/api';
  import constantsDisplayInformation from '@/filters/constants-display-information.filter';
  import { AUTH_ME } from '@/store/auth.module';
  import constantsProjectRoles from '@/filters/constants-project-roles.filter';

  export default {
    name: 'ProfileIndex',
    data: () => ({
      isOpened: false,
      routeName: ROUTE_NAME,
      randomNum: null,
      me: {},
      profilePhoto: {},
      userRatings: {
        rating: 0,
        totalRatingCount: 0,
        reviews: []
      }
    }),
    computed: {
      ...mapGetters({
        authUser: ['auth/me']
      }),
      userId() {
        return this.$route?.query?.id || null;
      },
      isTalentAgencyRole() {
        return this.me?.role === 'agency' || this.me?.role === 'Talent Agency';
      },
      isAgencyOrProductionHouse() {
        return ['agency', 'production_house', 'Talent Agency'].includes(
          this.me?.role
        );
      },
      displayInformations() {
        return constantsDisplayInformation(this.me?.display_information_ids);
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      projectRolesString() {
        if(!this.me.project_role_type_ids?.length) return '';
        else {
          const projectRoles = this.me.project_role_type_ids.map((x) => x.role_id);
          const projectRoleString = constantsProjectRoles(projectRoles, true).join(', ');
          return projectRoleString;
        }
      },
      avatarUrl() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      }
    },
    watch: {
      isOpened(val) {
        if (val) {
          bus.$emit('openNavBar', val);
        }
      },
      async userId() {
        this.me = {};
        await this.getUserProfile();
        await this.getRatingReviews();
      }
    },
    methods: {
      getCountry(id) {
        return (this?.$store?.getters['constants/allCountries'] || '').find(
          (i) => i.id === id
        );
      },
      openVerifyAccountModal() {
        if (!this.isAgencyOrProductionHouse && !this.randomNum) {
          const num = Math.floor(Math.random() * 5) + 1;
          this.randomNum = num;
        }
        this.$openModal('verifyAccount');
      },
      async verificationSubmit() {
        await this.$store.dispatch(AUTH_ME);
        this.me = {
          ...this.authUser
        };
        this.$nextTick(() => {
          this.$openModal(
            'messageModal',
            'Your request has been submitted, your account is being verified by our team.'
          );
        });
      },
      async getUserProfile() {
        if (this.userId && this.userId !== this.authUser.id) {
          try {
            const res = await PROFILE.getUserProfileById(this.userId);
            this.me = {
              ...res.data.user
            };
            this.profilePhoto =
              res.data.user.profile?.headshot_file?.media_path ||
              res.data.user.profile?.company_logo_file?.media_path ||
              this.avatarUrl;

            this.$closeModal();
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
        } else {
          try {
            const res = await AUTH.me();
            const profile = res?.data?.profile;
            const auth = res?.data?.auth;
            this.me = {
              ...profile,
              role: auth?.role,
              project_role_type_ids: auth?.project_role_type_ids || [],
              isUnder18: auth?.role == 'talent' && (uiHelper.getAge(profile.dob) < 18),
              guardian_relationship: auth?.guardian_relationship,
              guardian_gender: auth?.guardian_gender,
            };

            this.profilePhoto =
              this.me?.profile?.headshot_file?.media_path ||
              this.me?.profile?.company_logo_file?.media_path ||
              this.avatarUrl;

            this.$closeModal();
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
        }
      },
      async getRatingReviews() {
        this.$startLoading();

        try {
          const data = await PROFILE.getUserRating(
            this.userId || this.$route?.query?.id || this.authUser.id
          );
          this.userRatings = {
            rating: data?.data?.rating || 0,
            reviews: data?.data?.reviews || [],
            totalRatingCount: data?.data?.total_rating_count || 0,
          };
        } catch (e) {
          //
        }
        this.$stopLoading();
      },
      async followUser() {
        this.$startLoading();
        try {
          await PROFILE[`${this.me.followed ? 'unfollowUser' : 'followUser'}`](
            this.me?.id || this.userId
          );
          this.me.followed = !this.me.followed;
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      showVerifyModal() {
        document.getElementById('verifyModal').click();
      }
    },
    created() {
      bus.$on('closeNavBar', (data) => {
        this.isOpened = data;
      });
    },
    async mounted() {
      this.getUserProfile();
      this.getRatingReviews();
      if (this.$route?.params?.showVerifyModal) {
        // redirect from inbox to show verify account modal
        setTimeout(() => {
          this.showVerifyModal();
        }, 1000);
      }
    },
    components: {
      RatingAndReviews,
      ModalBase,
      VerifyAccount,
      ProfileSection,
      ShowreelSection
    }
  };
</script>

<style scoped lang="scss">
  .grey-bg {
    background-color: #242424;
  }
  .lg-font {
    font-size: 1.2rem;
  }

  .btn1.outline {
    border-radius: 5px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
  }
  .btn1.gradient {
    border-radius: 5px !important;
  }
  .btn1.fit {
    padding: 10px 50px !important;
  }

  .profilePic-container {
    position: relative;
    text-align: center;
    width: fit-content;
  }

  .verificationStatus {
    position: absolute;
    top: 90%;
    left: 85%;
    transform: translate(-50%, -50%);
  }
</style>
