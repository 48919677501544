import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"section-title d-flex"},[_c('div',{staticClass:"rating-container"},[_c(VImg,{staticClass:"rating-star",attrs:{"src":"/assets/image/icons/ratingStarActive.png","width":"60"}}),_c('div',{staticClass:"rating-num"},[_vm._v(_vm._s(_vm.userRatings.rating || 0))])],1),_c('div',{staticClass:"ml-5"},[_c('div',[_vm._v(" Rating & Reviews ("+_vm._s(_vm.userRatings.totalRatingCount || 0)+") "),_c('router-link',{staticClass:"text-decoration-none my-10",attrs:{"to":{
              name: _vm.routeName.RATING_REVIEWS,
              params: {
                userId: _vm.userId
              }
            }}},[_c(VIcon,{staticClass:"icon",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('div',[_vm._v(_vm._s(_vm.userRatings.rating)+"/5")])])]),_c(VCarousel,{attrs:{"hide-delimiters":"","height":"250"}},_vm._l((_vm.reviewCarousel),function(chunk,index){return _c(VCarouselItem,{key:index},[_c('div',{staticClass:"wh-100 d-flex align-center"},_vm._l((chunk),function(item,idx){return _c(VCard,{key:idx,staticClass:"mx-auto",attrs:{"max-width":"344","height":"160","outlined":"","dark":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"d-flex"},[_c(VRating,{attrs:{"hover":"","length":"5","readonly":"","size":"15","value":item.rating}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.defaultDate(item.created_at))+" ")])],1),_c(VListItemSubtitle,{staticClass:"d-flex my-3"},[_c(VAvatar,{staticClass:"me-1",attrs:{"size":"50"}},[_c('img',{attrs:{"src":_vm.getPhoto(item.photo),"crossorigin":"anonymous"}})]),_c('div',{staticClass:"align-self-center"},[_vm._v(_vm._s(item.name))])],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.comment)+" ")])],1)],1)],1)}),1)])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }