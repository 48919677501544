<template>
  <div>
    <div>
      <div class="section-title d-flex">
        <div class="rating-container">
          <v-img
            src="/assets/image/icons/ratingStarActive.png"
            class="rating-star"
            width="60"
          ></v-img>
          <div class="rating-num">{{ userRatings.rating || 0 }}</div>
        </div>
        <div class="ml-5">
          <div>
            Rating & Reviews ({{ userRatings.totalRatingCount || 0 }})
            <router-link
              class="text-decoration-none my-10"
              :to="{
                name: routeName.RATING_REVIEWS,
                params: {
                  userId
                }
              }"
            >
              <v-icon color="white" class="icon"> mdi-chevron-right </v-icon>
            </router-link>
          </div>
          <div>{{ userRatings.rating }}/5</div>
        </div>
      </div>
      <v-carousel hide-delimiters height="250">
        <v-carousel-item v-for="(chunk, index) in reviewCarousel" :key="index">
          <div class="wh-100 d-flex align-center">
            <v-card
              class="mx-auto"
              max-width="344"
              height="160"
              outlined
              dark
              v-for="(item, idx) in chunk"
              :key="idx"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="d-flex">
                    <v-rating
                      hover
                      length="5"
                      readonly
                      size="15"
                      :value="item.rating"
                    ></v-rating>

                    <div class="ml-2">
                      {{ defaultDate(item.created_at) }}
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="d-flex my-3">
                    <v-avatar size="50" class="me-1">
                      <img :src="getPhoto(item.photo)" crossorigin="anonymous" />
                    </v-avatar>
                    <div class="align-self-center">{{ item.name }}</div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.comment }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
  import Moment from 'moment';
  import { DEFAULT_DATE_FORMAT } from '@/constants/constants';
  import { ROUTE_NAME } from '@/constants';
  import { mapGetters } from 'vuex';

  export default {
    name: 'RatingAndReviews',
    props: {
      userId: {
        type: Number,
        default: null
      },
      userRatings: {
        type: Object,
        default: null
      }
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    computed: {
      ...mapGetters({
        authUser: ['auth/me']
      }),
      reviewCarousel() {
        const perChunk = this.$isMobile() ? 1 : 3; // items per chunk

        const result = this.userRatings?.reviews?.reduce(
          (resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          },
          []
        );

        return result;
      }
    },
    methods: {
      getPhoto(photo) {
        return photo?.media_path || this?.$store?.getters['constants/avatarImageUrl'];
      },
      defaultDate(date) {
        return Moment(date).format(DEFAULT_DATE_FORMAT);
      }
    }
  };
</script>

<style lang="scss">
  .theme--dark.v-sheet--outlined {
    border: thin solid rgba(255, 255, 255, 0.35);
  }
  .theme--dark.v-card {
    background-color: black;
  }

  .theme--dark.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle,
  .theme--dark.v-list-item:not(.v-list-item--disabled)
    .v-list-item__action-text {
    color: white;
  }

  .rating-container {
    position: relative;
    text-align: center;
    width: fit-content;
  }
  .rating-num {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .v-rating .v-icon {
    padding: 0;
  }
  .v-window__prev, 
  .v-window__next {
    margin: 0
  }
</style>
