import { render, staticRenderFns } from "./ProfileSection.vue?vue&type=template&id=0242a9c9&scoped=true&"
import script from "./ProfileSection.vue?vue&type=script&lang=js&"
export * from "./ProfileSection.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSection.vue?vue&type=style&index=0&id=0242a9c9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0242a9c9",
  null
  
)

export default component.exports