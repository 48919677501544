import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',[_c('div',{staticClass:"mt-10 w-100"},[(_vm.me.profile)?_c('ShowreelSection',{attrs:{"displayInformations":_vm.displayInformations,"profile":_vm.me.profile}}):_vm._e(),(_vm.me.profile && _vm.me.profile.company_showreel_video_file)?_c('div',{staticClass:"w-100 justify-center d-flex"}):_vm._e(),_c('div',{staticClass:"section-title d-flex my-10"},[_c('div',{staticClass:"profilePic-container"},[_c(VAvatar,{attrs:{"size":"80"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.profilePhoto,"crossorigin":"anonymous"}})]),(_vm.me.profile)?_c('div',{staticClass:"verificationStatus"},[(_vm.me.profile.verification_status == 'approved')?_c(VIcon,{staticClass:"icon",attrs:{"color":"#FF2FF2"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"ml-5 align-self-center"},[(_vm.me.profile)?_c('div',{staticClass:"lg-font"},[_vm._v(" "+_vm._s(_vm.me.display_name || _vm.me.profile.display_name)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[(_vm.displayInformations.country)?_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"me-2",attrs:{"src":_vm.getCountry(_vm.me.country_id[0]).image_url,"width":"35px","crossorigin":"anonymous"}}),_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.getCountry(_vm.me.country_id[0]).name))]),(_vm.me.role == 'talent' && _vm.displayInformations.description)?[_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.projectRolesString))])]:_vm._e()],2):_vm._e()])]),(!_vm.$isMobile())?_c('div',{staticClass:"ml-auto align-self-center"},[(
              _vm.me.profile &&
              !['approved', 'submitted'].includes(
                _vm.me.profile.verification_status
              ) &&
              _vm.authUser.id == _vm.me.id
            )?_c(VBtn,{staticClass:"btn1 fit outline",attrs:{"id":"verifyModal"},on:{"click":_vm.openVerifyAccountModal}},[_vm._v(" "+_vm._s(_vm.me.profile.verification_status == 'rejected' ? 'Not Verified' : 'Verify Account')+" ")]):_vm._e(),(
              _vm.me.profile &&
              ['submitted'].includes(_vm.me.profile.verification_status) &&
              _vm.authUser.id == _vm.me.id
            )?_c(VBtn,{staticClass:"btn1 fit outline",attrs:{"disabled":""}},[_vm._v(" Pending Approval ")]):_vm._e(),(_vm.userId && _vm.authUser.id != _vm.me.id)?_c(VBtn,{staticClass:"btn1 fit",class:_vm.me.followed ? 'outline' : 'gradient',on:{"click":_vm.followUser}},[_vm._v(" "+_vm._s(_vm.me.followed ? 'Following' : 'Follow')+" ")]):_vm._e()],1):_vm._e()]),(_vm.$isMobile())?_c('div',[(
            _vm.me.profile &&
            !['approved', 'submitted'].includes(
              _vm.me.profile.verification_status
            ) &&
            _vm.authUser.id == _vm.me.id
          )?_c(VBtn,{staticClass:"btn1 fit outline w-100",on:{"click":_vm.openVerifyAccountModal}},[_vm._v(" Verify Account ")]):_vm._e(),(_vm.userId && _vm.authUser.id != _vm.me.id)?_c(VBtn,{staticClass:"btn1 fit w-100",class:_vm.me.followed ? 'outline' : 'gradient',on:{"click":_vm.followUser}},[_vm._v(" "+_vm._s(_vm.me.followed ? 'Following' : 'Follow')+" ")]):_vm._e()],1):_vm._e(),_c(VRow,{staticClass:"grey-bg my-5",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":{
              name: _vm.routeName.FOLLOWER_FOLLOWING,
              params: {
                tab: 'followers'
              },
              query: {
                id: _vm.authUser.id == _vm.me.id ? null : _vm.me.id
              }
            }}},[_c('p',{staticClass:"mt-4"},[_vm._v("Followers")]),_c('p',{staticClass:"pink2--text"},[_vm._v(_vm._s(_vm.me.follower))])])],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":{
              name: _vm.routeName.FOLLOWER_FOLLOWING,
              params: {
                tab: 'followings'
              },
              query: {
                id: _vm.authUser.id == _vm.me.id ? null : _vm.me.id
              }
            }}},[_c('p',{staticClass:"mt-4"},[_vm._v("Following")]),_c('p',{staticClass:"pink2--text"},[_vm._v(_vm._s(_vm.me.following))])])],1),(_vm.displayInformations.talents_no)?_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":{
              name: _vm.routeName.FOLLOWER_FOLLOWING,
              params: {
                tab: 'talents'
              },
              query: {
                id: _vm.authUser.id == _vm.me.id ? null : _vm.me.id
              }
            }}},[_c('p',{staticClass:"mt-4"},[_vm._v("Talents")]),_c('p',{staticClass:"pink2--text"},[_vm._v(_vm._s(_vm.me.talents_no))])])],1):_vm._e()],1),(_vm.userRatings.totalRatingCount)?_c('RatingAndReviews',{staticClass:"my-12",attrs:{"userRatings":_vm.userRatings,"userId":_vm.userId}}):_vm._e(),_c('ProfileSection',{attrs:{"me":_vm.me,"profile":_vm.me.profile,"displayInformations":_vm.displayInformations}})],1)]),_c('ModalBase',{attrs:{"id":"verifyAccount"}},[_c('VerifyAccount',{attrs:{"isAgencyOrProductionHouse":_vm.isAgencyOrProductionHouse,"randomNum":_vm.randomNum,"constantsData":_vm.constantsData},on:{"success":_vm.verificationSubmit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }