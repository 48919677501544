import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

export default function constantsDisplayInformation(displayInformationsIds) {
  let displayInformationsObj = {};
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  let header = 'display_informations';
  displayInformationsIds?.forEach((id) => {
    const value = constantsData[header].find((x) => x.id == id);
    if (value) {
      displayInformationsObj = {
        ...displayInformationsObj,
        ...Object.fromEntries([[value.key.toLowerCase(), true]])
      };
    }
  });

  return displayInformationsObj;
}
