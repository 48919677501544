import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

export default function constantsKey(header, value, returnTextOnly = false) {
  let description;
  let newArray = [];

  if (header && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          newArray.push(getKey(header, x, returnTextOnly));
        });
        description = newArray.join(', ');
      } else {
        description = '-';
      }
    } else {
      description = getKey(header, value, returnTextOnly);
    }
  } else {
    description = '-';
  }

  return description;
}

function getKey(header, value, returnTextOnly) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  const data =
    header == 'race'
      ? constantsData[header][value]
      : constantsData[header].find((x) => x.value == value);

  return returnTextOnly ? data.description : data;
}
