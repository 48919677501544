<template>
  <div>
    <v-carousel
      :show-arrows="false"
      class="text-center"
      v-if="showreel"
      height="500"
    >
      <v-carousel-item
        v-if="
          displayInformations.company_showreel_video &&
          items.company_showreel_video_file
        "
      >
        <video controls class="wh-100" crossorigin="anonymous">
          <source
            :src="items.company_showreel_video_file.media_path"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </v-carousel-item>
      <template v-if="displayInformations.company_showreel_image">
        <v-carousel-item
          v-for="image in items.company_showreel_image_file"
          :key="image.id"
        >
          <img :src="image.media_path" class="showreel_img" crossorigin="anonymous"/>
        </v-carousel-item>
      </template>
      <template v-if="displayInformations.intro_video">
        <v-carousel-item
          v-for="video in items.intro_video_files"
          :key="video.id"
        >
          <video controls class="wh-100" crossorigin="anonymous">
            <source :src="video.media_path" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </v-carousel-item>
      </template>
      <template v-if="displayInformations.gallery">
        <v-carousel-item
          v-for="image in items.gallery_file"
          :key="image.id"
        >
          <img :src="image.media_path" class="showreel_img" crossorigin="anonymous"/>
        </v-carousel-item>
      </template>
    </v-carousel>
  </div>
</template>

<script>
  export default {
    name: 'ShowreelSection',
    props: ['displayInformations', 'profile'],
    data() {
      return {
        items: {
          company_showreel_video_file: null,
          company_showreel_image_file: [],
          intro_video_files: [],
          gallery_file: []
        },
        reelContent: [
          {
            displayKey: 'company_showreel_video',
            profileKey: 'company_showreel_video_file'
          },
          {
            displayKey: 'company_showreel_image',
            profileKey: 'company_showreel_image_file'
          },
          {
            displayKey: 'intro_video',
            profileKey: 'intro_video_files'
          },
          {
            displayKey: 'gallery',
            profileKey: 'gallery_file'
          }
        ]
      };
    },
    computed: {
      showreel() {
        let show = false;
        show = this.reelContent.some(
          (x) =>
            this.profile[x.profileKey] && this.displayInformations[x.displayKey]
        );
        return show;
      }
    },
    mounted() {
      this.items = {
        ...this.profile,
        company_showreel_video_file:
          this.profile?.company_showreel_video_file || null,
        company_showreel_image_file:
          this.profile?.company_showreel_image_file || [],
        intro_video_files: this.profile?.intro_video_files || [],
        gallery_file: this.profile?.gallery_file || []
      };
    }
  };
</script>

<style scoped lang="scss">
  video {
    height: auto;
  }
  .v-window-item {
    height: 95%;
  }
  ::v-deep .v-image.v-responsive.v-carousel__item {
    height: inherit !important;
  }

  img.showreel_img {
    object-fit: contain;
    max-height: 500px;
    max-width: 100%;
  }
</style>
